import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import {
  squashEventFields,
  hasEventIntersaction,
  toUTC,
  isEditableDate,
  locale,
  showModal
} from "../components/fullcalendar.js";
import { initializeRadioButtons } from "../components/square-radio-button";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { t } from "../i18n/t";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  connect() {
    this.schedulerMount();
    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.schedulerUnmount();
      },
      { once: true }
    );
  }

  schedulerMount() {
    const resourcesUrl = this.data.get("resources-url");
    const eventsUrl = this.data.get("events-url");
    const calendarLocale = locale()

    const calendar = new Calendar(this.element, {
      plugins: [resourceTimelinePlugin, interactionPlugin],
      timeZone: "UTC",
      initialView: "resourceTimelineWeeks",
      firstDay: 1,
      weekNumbers: true,
      height: "auto",
      locale: calendarLocale.slice(0, 2),
      headerToolbar: {
        left: "today",
        center: "prev,title,next",
        right:
          "resourceTimelineWeeks,resourceTimelineMonth,resourceTimelineQuarter",
      },
      dateClick: ({ date, resource }) => {
        const utcDate = toUTC(date);
        if (!isEditableDate(utcDate)) return;

        const listingId = resource.id
        const visibleEvents = calendar.getEvents();
        const listingEvents = visibleEvents.filter(event => event._def.resourceIds.includes(listingId));

        if (hasEventIntersaction({ start: utcDate, end: utcDate }, listingEvents)) return;

        const url = `/v2/en/listings/${listingId.trim()}/availabilities`;

        showModal({
          type: "availability",
          start: utcDate,
          end: utcDate,
          url: url,
          is_available: false
        }, calendar);
      },
      eventClick: ({ event, jsEvent }) => {
        jsEvent.preventDefault();

        let url = event.extendedProps.conversation_link;
        if (url !== undefined && url !== null && url.length > 0) {
          Turbolinks.visit(url);
        }

        if (jsEvent.target.classList.contains("calendar-event") || jsEvent.target.classList.contains("fc-event")) {
          jsEvent.stopPropagation();
          showModal(squashEventFields(event), calendar);
          return
        }

        jsEvent.target.parentNode.click();
      },
      eventContent: ({ event }, createElement) => {
        let iconClasses = [];

        if (event.classNames.includes("availability--booking-approved")) {
          iconClasses.push("icon-checkmark-circle");
        } else if (event.classNames.includes("availability--booking-pending")) {
          iconClasses.push("icon-clock");
        } else if (event.classNames.includes("availability--booking-offered")) {
          iconClasses.push("icon-clock");
        }

        return createElement("div", { class: "calendar-event" }, [
          createElement(
            "div",
            { class: "calendar-event-title", title: event.title },
            [
              createElement("i", { class: iconClasses.join(" ") }, null),
              event.title,
            ]
          ),
        ]);
      },
      buttonText: {
        today: t("scheduler.today", calendarLocale),
      },
      views: {
        resourceTimelineWeeks: {
          type: "resourceTimeline",
          duration: { days: 15 },
          buttonText: t("scheduler.weeks", calendarLocale),
          slotLabelFormat: [{ week: "short" }, { weekday: "short" }, { day: "numeric" }],
        },
        resourceTimelineMonth: {
          type: "resourceTimeline",
          duration: { months: 1 },
          buttonText: t("scheduler.month", calendarLocale),
          slotLabelFormat: [{ week: "short" }, { weekday: "short" }, { day: "numeric" }],
        },
        resourceTimelineQuarter: {
          type: "resourceTimeline",
          duration: { months: 3 },
          buttonText: t("scheduler.quarter", calendarLocale),
          slotLabelFormat: [
            { month: "long" },
            { week: "short" },
            { weekday: "short" },
            { day: "numeric" },
          ],
        },
      },
      resourceAreaHeaderContent: t("listings.listings_title", calendarLocale),
      resourceLabelContent: (arg, createElement) => {
        const url = arg.resource.extendedProps["calendarUrl"];
        const title = arg.resource.title;
        return createElement(
          "a",
          { href: url, "data-turbolinks": "false" },
          title
        );
      },
      resourceGroupField: "building",
      resources: resourcesUrl,
      resourceOrder: "index",
      events: (info, successCallback) => {
        fetch(`${eventsUrl}&start=${info.startStr}&end=${info.endStr}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": getCsrfToken(),
          },
        })
          .then((response) => response.json())
          .then((data) => successCallback(data));
      },
      slotDuration: "24:00:00",

      // License
      schedulerLicenseKey: "0903568687-fcs-1610921093",
    });

    calendar.render();
    initializeRadioButtons();
    this.calendar = calendar
  }

  schedulerUnmount() {
    //not needed?
    //this.element.fullCalendar('destroy');
  }
}
