import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["companyNameAsterisk"];

  onBusinessTypeSelect(event) {
    if (event.currentTarget.querySelector('input').value === 'company') {
      this.companyNameAsteriskTarget.classList.remove("is-hidden");
    } else {
      this.companyNameAsteriskTarget.classList.add("is-hidden");
    }
  }
}
