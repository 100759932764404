import { Controller } from 'stimulus'
import $ from "jquery";

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.select2mount()

    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount();
    }, { once: true });
  }

  select2mount() {
    if (!this.hasSelectTarget) return;

    let $el = $(this.selectTarget);
    const that = this;
    const posOpts = $el.attr('data-pos') && JSON.parse($el.attr('data-pos'));

    let opts = Object.assign({
    }, posOpts || {});

    let searchBox = opts['searchBox'] === true;
    if (!searchBox) {
      opts['minimumResultsForSearch'] = Infinity;
    }


    if (opts['ajax']) {
      opts['ajax']['processResults'] = function (data, page) {
        return {
          results: $.map(data, function (x, i) {
            return { id: x.id, text: x.text }
          })
        }
      };
    }

    // "multiple" option should be set in "true" for correct "checkbox" behaviour
    if (opts['type'] === 'checkbox') {
      opts.templateSelection = function (selected, total) {
        const selection = $el.siblings(".select2").find(".selection");
        let templateSelectionString = opts.placeholder || "";

        if ($(".select2-results").length === 0) {
          if (selected.length > 0) {
            const defaultTemplate = "Selected %selected of %total total";
            templateSelectionString = opts.templateSelectionString || defaultTemplate;
            templateSelectionString = templateSelectionString.replace("%selected", selected.length)
                                                             .replace("%total", total);
          }
        } else {
          const search_val = $el.data("select2").selection.$search.val();
          if (search_val) {
            templateSelectionString = `<span class="search-stub">${search_val}</span>`;
          }
        }

        const selectionContent = `<span class="select2-selection select2-selection--single" role="combobox"
                                        aria-haspopup="true" aria-expanded="false" tabindex="0"
                                        aria-disabled="false" aria-labelledby="select2-listing_ids-container">
                                    <span class="select2-selection__rendered" id="select2-listing_ids-container"
                                          role="textbox" aria-readonly="true">
                                       <div class="select2-placeholder-with-counter">${templateSelectionString}</div>
                                    </span>
                                    <span class="select2-selection__arrow" role="presentation">
                                       <b role="presentation"></b>
                                    </span>
                                  </span>`

        selection.find(".select2-selection.select2-selection--multiple").hide();
        selection.find(".select2-selection.select2-selection--single").remove();
        selection.append(selectionContent);
        that.highlightSelect();

        return templateSelectionString;
      }

      $el.select2MultiCheckboxes(opts);
      $(`#${$el.attr("id")} + .select2 .selection`).on("click", (event) => {
        const selection = $(event.currentTarget);
        selection.find(".select2-selection.select2-selection--single").hide();
        selection.find(".select2-selection.select2-selection--multiple").show();
        selection.find(".select2-selection.select2-selection--multiple .select2-selection__choice").remove();
        selection.find(".select2-search__field").focus();
        selection.find(".select2-search__field").trigger($.Event("keyup", { which: 40 }));
      });

      if (opts["allowClear"]) {
        $el.on("select2:open", () => {
          const dropdown = $(".select2-dropdown.select2-dropdown--below");
          dropdown.find(".select2-selection__clear").remove();

          const clearBtn = document.createElement("div");
          clearBtn.classList.add("select2-selection__clear");
          clearBtn.innerText = opts["clearString"] || "Clear";
          clearBtn.addEventListener("click", event => {
            event.stopPropagation();
            dropdown.find(".select2-results__option").attr("aria-selected", "false");
            $el.val(null).trigger('change');
          })
          dropdown.append(clearBtn);
        });
      }

      $el.on('select2:close', () => {
        const event = new Event('change', { bubbles: true });
        $el[0].dispatchEvent(event);
      });

      return
    }

    if (opts['type'] === 'emails') {
      function validateEmail(email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
      }

      opts['createTag'] = function (params) {
        if(!validateEmail(params.term)) {
          // Return null to disable tag creation
          return null;
        }
        return {
          id: params.term,
          text: params.term
        }
      }
    }

    $el.select2(opts);

    if (opts['prefix']) {
      $el.on('change', () => {
        const selection = $el.siblings(".select2").find(".select2-selection--single");
        const renderedArea = selection.find('.select2-selection__rendered');
        let placeholder = `${opts['prefix']} ${renderedArea.text()}`;
        placeholder = placeholder.charAt(0).toUpperCase() + placeholder.slice(1);

        renderedArea.text(placeholder);
      });
    }

    if (!opts["multiple"] && opts["searchBox"]) {
      const selection = $el.siblings(".select2").find(".select2-selection--single");
      const renderedArea = selection.find(".select2-selection__rendered");
      renderedArea.addClass("is-hidden");

      const pseudoSearchInput = document.createElement("input");
      pseudoSearchInput.classList.add("select2-single-pseudo-search-input");
      pseudoSearchInput.value = renderedArea.text().replace("×", "");
      if ($el.val()) pseudoSearchInput.classList.add("filled");
      pseudoSearchInput.addEventListener("keyup", (e) => {
        const originalSearchInput = document.querySelector(`.select2-dropdown.select2-dropdown--below .select2-search--dropdown input,
                                                            .select2-dropdown.select2-dropdown--above .select2-search--dropdown input`);

        // by some reason when dropdown is placed to modal dialog
        // it blocks space symbols typing inside searchbox
        // this hack allow to solve this issue manually
        if (e.keyCode === 32) {
          const caretPos = e.target.selectionStart + 1;
          pseudoSearchInput.value = pseudoSearchInput.value.slice(0, e.target.selectionStart) +
                                    " " +
                                    pseudoSearchInput.value.slice(e.target.selectionEnd);
          pseudoSearchInput.setSelectionRange(caretPos, caretPos);
          pseudoSearchInput.focus();
        }
        originalSearchInput.value = pseudoSearchInput.value;
        originalSearchInput.dispatchEvent(new Event("keyup"));
      });
      pseudoSearchInput.addEventListener("click", event => {
        event.stopPropagation();

        if (!$el.val()) pseudoSearchInput.value = "";
      });
      selection.prepend(pseudoSearchInput);

      if (opts["allowClear"]) {
        const clearBtn = document.createElement("span");
        clearBtn.classList.add("select2-selection__clear", "absolute", "top-3", "right-9");
        clearBtn.innerText = "×";
        clearBtn.addEventListener("click", event => {
          event.stopPropagation();
          renderedArea.find(".select2-selection__clear").trigger("click");
        })
        selection.append(clearBtn);
      }

      $el.on("select2:open", () => {
        const originalSearchBox = $(".select2-dropdown.select2-dropdown--below .select2-search--dropdown");
        originalSearchBox.addClass("is-hidden");
      });

      $el.on("select2:close", () => {
        pseudoSearchInput.value = renderedArea.text().replace("×", "");
        if ($el.val()) {
          pseudoSearchInput.classList.add("filled");
        } else {
          pseudoSearchInput.classList.remove("filled");
        }
      })
    }

    const selectedValues = $el.children("option:selected").map(function () { return this.value }).get();
    $el.val(selectedValues).trigger('change');

    this.highlightSelect();

    $el.on('select2:select select2:unselect', function (e) {
      let event = new Event('change', { bubbles: true });
      that.selectTarget.dispatchEvent(event);

      that.highlightSelect();
    });
  }

  highlightSelect() {
    const $select = $(this.selectTarget);
    const selection = $select.siblings(".select2").find(".select2-selection.select2-selection--single");
    if ($select.val()) {
      selection.addClass('filled');
    } else {
      selection.removeClass('filled');
    }
  }

  get galleryController() {
    return this.application.controllers.find(c => c.identifier === 'gallery')
  }

  saveState() {
    // TODO: I suspect this is not working for multiple select with remote options, need to add a test.
    let value = $(this.selectTarget).val()
    $(this.selectTarget).find(`option[value="${value}"]`).attr('selected', 'selected')
  }

  select2unmount() {
    this.saveState();

    // to avoid the famous error:
    // The select2('destroy') method was called on an element that is not using Select2”
    //
    // https://select2.org/programmatic-control/methods#checking-if-the-plugin-is-initialized
    // if($(this.selectTarget).hasClass("select-hidden-acccessible")) {
    //     $(this.selectTarget).select2('destroy');
    // }

    try { $(this.selectTarget).select2('destroy'); } catch (e) { }
  }
}
